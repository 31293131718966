var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: _vm.classes, style: _vm.placeholderStyles },
    [
      _c(
        "div",
        { staticClass: "placeholder__description" },
        [
          _vm.title
            ? _c("PlaceholderTitle", {
                attrs: { size: _vm.size, title: _vm.title }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.subtitle
            ? _c("PlaceholderSubtitle", { attrs: { title: _vm.subtitle } })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("action")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }