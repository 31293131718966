var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Button", {
    staticClass: "placeholder-clear-search-button",
    attrs: { title: _vm.title, size: _vm.smallSize, theme: _vm.secondaryTheme },
    scopedSlots: _vm._u([
      {
        key: "prepend",
        fn: function(ref) {
          var color = ref.color
          return [_c("IconFilledClose", { attrs: { "fill-color": color } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }